// Add your custom JS here.

jQuery( document ).ready(function() {
   
    jQuery('.logo-slidar').slick({
      speed: 3000,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: 'linear',
      slidesToShow: 7,
      slidesToScroll: 1,
      infinite: true,
      swipeToSlide: true,
      centerMode: false,
      focusOnSelect: true,
      dots: false,
      variableWidth: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ]
      });
	// Slick slidar js end

jQuery('.join-team-slider-marquee').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  speed: 8000,
  pauseOnHover: false,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },    
  ],  
    
  });
  jQuery('.join-team-slider-marquee-two').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 7000,
    pauseOnHover: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },    
    ], 
      
    });
 
 
 jQuery('.testimonial-woocommerce-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    adaptiveHeight: true, 
    });


  jQuery('.testimonial-full-width-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    adaptiveHeight: true,
    });

	jQuery(window).scroll(function(){
	    if(jQuery(this).scrollTop() > 100){
	        jQuery('.navigation-menu').addClass('sticky')
	    } else{
	        jQuery('.navigation-menu').removeClass('sticky')
	    }
	});

	jQuery(".navbar-toggler").click(function(){
		jQuery("body").toggleClass("menu-open");
	});

  // if(jQuery('#switchYearly').is(':checked')) {
  //   jQuery(".pricing-section .monthly").hide();
  //   jQuery(".pricing-section .yearly").show();
  // }else{
  //   jQuery(".pricing-section .monthly").show();
  //   jQuery(".pricing-section .yearly").hide();
  // }
  // jQuery(".switches-container").click(function(){
  //   if(jQuery('#switchYearly').is(':checked')) {
  //     jQuery(".pricing-section .monthly").hide();
  //     jQuery(".pricing-section .yearly").show();
  //   }else{
  //     jQuery(".pricing-section .monthly").show();
  //     jQuery(".pricing-section .yearly").hide();
  //   }
  // });


  jQuery(".menu-item-has-children > a").after('<span class="arrow-icon"></span>');
    jQuery(".arrow-icon").click(function() {
      jQuery(this).parent().toggleClass("menu-open");
        jQuery(this).next().toggleClass("drop-open");
    });


    // Blog Title Equal Height
    // var blogTitle = 0;
    // setTimeout(function(){
    // jQuery(".blog-block .inner-content h4").each(function(){
    // if (jQuery(this).height() > blogTitle) { blogTitle = jQuery(this).height(); }
    // });
    // jQuery(".blog-block .inner-content h4").height(blogTitle);
    // });


    (function( $ ) {
      $.fn.tabConvert = function(options) {
        
        var settings = $.extend({
          activeClass: "active",
          screenSize: 767,
        }, options );
    
        return this.each(function(i) {
          var wrap = $(this).wrap('<div class="tab-to-dropdown"></div>'),
              currentItem = $(this),
              container = $(this).closest('.tab-to-dropdown'),
              value = $(this).find('.'+settings.activeClass).text(),
              toggler = '<div class="selected-tab">'+ value +'</div>';
          currentItem.addClass('converted-tab');
          container.prepend(toggler);
          
          // function to slide dropdown
          function tabConvert_toggle(){
            currentItem.parent().find('.converted-tab').slideToggle();
          }
    
          container.find('.selected-tab').click(function(){
            tabConvert_toggle();
          });
          
          currentItem.find('li').click(function(e){
            var windowWidth = window.innerWidth;
            if( settings.screenSize >= windowWidth){
              tabConvert_toggle();
              var selected_text = $(this).text();
              $(this).closest('.tab-to-dropdown').find('.selected-tab').text(selected_text);
            }
          });
          
          //Remove toggle if screen size is bigger than defined screen size
          function resize_function(){
            var windowWidth = window.innerWidth;
            if( settings.screenSize >= windowWidth){
              currentItem.css('display','none');
              currentItem.parent().find('.selected-tab').css('display','');
            }else{
              currentItem.css('display','');
              currentItem.parent().find('.selected-tab').css('display','none');
            }
          }
          resize_function();
          
          $(window).resize(function(){
            resize_function();
          });
          
        });
      };
    
      // 	Toggle will appear on default screen size 767px
      jQuery('.faq-section .faq-navigation').tabConvert({
      activeClass: "active",
      });
      
    
    }( jQuery ));

});


// FAQ
jQuery(window).scroll(function() {
  var windscroll = jQuery(window).scrollTop();
  if (windscroll >= 100) {
    jQuery('.scrollable').each(function(i) {
      if (jQuery(this).position().top <= windscroll + 300) {
        jQuery('.faq-navigation li.active').removeClass('active');
        jQuery('.faq-navigation li').eq(i).addClass('active');
      }
    });

  } else {
    jQuery('.faq-navigation li.active').removeClass('active');
    jQuery('.faq-navigation li:first').addClass('active');
  }

  }).scroll();

jQuery(".faq-navigation li a").click(function(){
  var href = jQuery(this).attr( 'href' );
  jQuery( 'html, body' ).animate({
    scrollTop: jQuery( href ).offset().top - 100
  }, '300' );
  e.preventDefault();

  jQuery(".faq-navigation li").removeClass("active");
  jQuery(this).parent().addClass("active");
});




// setInterval(function(){
//   if(document.activeElement instanceof HTMLIFrameElement){
//       document.querySelector('.placeholder-img').style.opacity = 0; 
//       const parentElement  = document.querySelector('.ifrme-video');
//       if (parentElement) {
//           parentElement.querySelector('iframe').style.opacity = 1;
//       }
//   }
// }, 50);

setInterval(function(){
  // Check if there's at least one element with the 'placeholder-img' class
  const placeholderImg = document.querySelector('.placeholder-img');
  if(placeholderImg){ // If the element exists
    if(document.activeElement instanceof HTMLIFrameElement){
      // Set opacity of the placeholder image to 0
      placeholderImg.style.opacity = 0; 

      // Find the parent element with the 'iframe-video' class
      const parentElement = document.querySelector('.ifrme-video');
      if (parentElement) {
          // Set opacity of the iframe inside the 'iframe-video' container to 1
          const iframe = parentElement.querySelector('iframe');
          if (iframe) {
              iframe.style.opacity = 1;
          }
      }
    }
  }
}, 50);



// jQuery(document).ready(function () {
//   setTimeout(function(){
//     jQuery('.ifrme-video iframe').css("opacity", "1");
//   }, 200);
//   setTimeout(function(){
//     jQuery('.ifrme-video iframe').css("opacity", "0");
//   }, 250);
// });


jQuery(document).ready(function($) {
  $('.copy-button').on('click', function() {
      const $codeElement = $(this).parent('.code-toolbar').find('.code-snippet').find('code');
      const codeText = $codeElement.text();

      // Create a temporary textarea element to copy the code
      const $textarea = $('<textarea>');
      $textarea.text(codeText);
      $('body').append($textarea);
      $textarea[0].select();

      try {
          // Use the Clipboard API to copy the selected text
          document.execCommand('copy');
          const $copyButton = $(this);
          $copyButton.text('Copied!');
          setTimeout(function() {
              $copyButton.text('Copy');
          }, 2000);
      } catch (err) {
          console.error('Unable to copy to clipboard:', err);
      } finally {
          // Clean up: remove the textarea
          $textarea.remove();
      }
  });
});


jQuery(document).ready(function () {
  // Check if cookie is set or not
  if (getCookie('ecommercehints_popup') !== 'ebook_opted') {
    var modalShownCount = 0;
    var inactivityTimeout = 210000; // 210 seconds in milliseconds

    var showModal = function () {
      if (modalShownCount < 2) {
        jQuery('#modal-subscribe').modal("show");
        modalShownCount++;
      }
    };

    var inactivityTimer;

    var resetInactivityTimer = function () {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(showModal, inactivityTimeout);
    };

    // Reset the inactivity timer on various user interactions (e.g., clicks, scrolls)
    jQuery(document).on('click scroll', resetInactivityTimer);

    // Show the modal on page exit intent
    jQuery(document).mouseleave(function (e) {
      if (e.clientY <= 0) {
        showModal();
      }
    });

    // Start the initial inactivity timer
    resetInactivityTimer();
  }
});

// Function to get a cookie by name
function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length === 2) {
    return decodeURIComponent(parts.pop().split(";").shift());
  }
}

function openLightbox() {
  var lightbox = document.getElementById('lightbox');
  var lightboxImg = document.getElementById('lightbox-img');
  var imageTrigger = document.querySelector('.lightbox-trigger');

  lightbox.style.display = 'block';
  lightboxImg.src = imageTrigger.src;
}

function closeLightbox() {
  var lightbox = document.getElementById('lightbox');
  lightbox.style.display = 'none';
}


jQuery(document).ready(function($) {
  // Function to animate counters
  function animateCounters() {
      $('.counter').each(function() {
          var $this = $(this),
              countTo = $this.attr('data-count');
              
          $({ countNum: $this.text() }).animate({
              countNum: countTo
          },
          {
              duration: 2000, // Duration of the animation
              easing: 'swing',
              step: function() {
                  $this.text(Math.floor(this.countNum));
              },
              complete: function() {
                  $this.text(this.countNum);
              }
          });
      });
  }

  // Function to check if element is in viewport
  function isScrolledIntoView(elem) {
      var $elem = $(elem);
      var $window = $(window);
      var docViewTop = $window.scrollTop();
      var docViewBottom = docViewTop + $window.height();
      var elemTop = $elem.offset().top;
      var elemBottom = elemTop + $elem.height();
      return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  }

  // Check if the counter section is in view on page load
  if (isScrolledIntoView('.counter-wrapper')) {
      animateCounters();
  }

  // Check if the counter section is in view on scroll
  $(window).on('scroll', function() {
      if (isScrolledIntoView('.counter-wrapper')) {
          animateCounters();
          $(window).off('scroll'); // Remove the scroll event listener after animating once
      }
  });

  $('.left-slider').slick({
      speed: 9000,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: 'linear',
      slidesToShow: 5,
      infinite: true,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      variableWidth: true
    });

      $('.right-slider').slick({
      speed: 9000,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: 'linear',
      slidesToShow: 5,
      infinite: true,
      swipeToSlide: true,
      arrows: false,
      dots: false, // pager: false
      rtl:true,
      variableWidth: true
    });
});
